import React from "react"
import { StoryGroup } from "components"
import { flatStoryGroup } from "utils/array-util"
import { getEBooksCoverUrl } from "utils/rsc-util"

const eBooks = [
  {
    title: "How to control re-renders on React Class Components",
    url:
      "https://learnreactui.dev/contents/how-to-control-re-renders-on-react-class-components",
    img: getEBooksCoverUrl("023_performance-class-comp"),
  },
  {
    title: "How to control re-renders on React Function Components",
    url:
      "https://learnreactui.dev/contents/how-to-control-re-renders-on-react-func-components",
    img: getEBooksCoverUrl("024-peformance-func-comp"),
  },
  {
    title: "React Tab Visibility Optimization",
    url: "https://learnreactui.dev/contents/react-tab-visibility-optimization",
    img: getEBooksCoverUrl("025-react-tab-visibility-optimization"),
  },
  {
    title: "React Client State Management",
    url:
      "https://learnreactui.dev/contents/mastering-react-client-state-management",
    img: getEBooksCoverUrl("019_client-state-management"),
  },
  {
    title: "Zustand Re-Render Example",
    url: "https://learnreactui.dev/contents/zustand-rerender",
    img: getEBooksCoverUrl("072-zustand-rerender"),
  },
]

export const LinkMap = {
  "Performance Examples": "9ed16a3b6e65",
  "Client State Examples": "57a16b1d235a",
  "Zustand Examples": "fcbfeb71fc68",
  shouldComponentUpdate: "76e2495fdc3b",
  PureComponent: "76e2495fdc3b",
  "React.memo": "76e2495fdc3b",
  useCallback: "e9957e672c3e",
  "document.hidden": "80e8623494db",
  lazy: "3ae591a58e81",
  suspense: "3ae591a58e81",
  useDeferredValue: "3ae591a58e81",
  useTransition: "3ae591a58e81",
  reactFiber: "274be4fbe44f",
}

const generalPerformance = [
  {
    title: "Web Vitals",
    postId: "6ad6f5141333",
  },
  {
    title: "React Fiber",
    postId: "274be4fbe44f",
  },
  {
    title: "React Performance",
    postId: "71f0c7a714e5",
  },
  {
    title: "React Performance-2 (Concurrency)",
    postId: "3ae591a58e81",
  },
]

const storiesHooksPerf = [
  {
    title: "React Performance-1 (re-render)",
    postId: "76e2495fdc3b",
  },
  {
    title: "React Performance-2 (useCallback)",
    postId: "e9957e672c3e",
  },
  {
    title: "React Performance-3 (useMemo)",
    postId: "6f52578e3fa0",
  },
]

const storiesWebAPIPerformance = [
  {
    title: "Tab Visibility Optimization",
    postId: "80e8623494db",
  },
]

const zustandPerformance = [
  {
    title: "Zustand, DevTool Integration",
    postId: "4262c09ce22f",
  },
  {
    title: "Zustand Re-Render Use Case",
    postId: "d97a58188625",
  },
  {
    title: "Zustand Re-Render Use Case2",
    postId: "853f01ede4e8",
  },
]

const storiesGroup = [
  {
    title: "General Performance",
    storyPart: generalPerformance,
  },
  {
    title: "Hooks Performance",
    storyPart: storiesHooksPerf,
  },
  {
    title: "Web API Performance",
    storyPart: storiesWebAPIPerformance,
  },
  {
    title: "Zustand Performance",
    storyPart: zustandPerformance,
  },
]

export const context = {
  stories: flatStoryGroup(storiesGroup),
  linkMap: LinkMap,
  title: "React Performance",
  path: "react-performance",
}

const ReactPerformancePage = () => {
  return (
    <StoryGroup
      title={context.title}
      complex={true}
      stories={storiesGroup}
      linkMap={context.linkMap}
      eBooks={eBooks}
    />
  )
}

export default ReactPerformancePage
